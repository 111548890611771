import {
  Validator,
} from 'vee-validate';
import {
  TheMask,
} from 'vue-the-mask';
import vxDropdownPrimary from '../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import globals from '../../common/globals';
import AcsService from '../../common/services/acs-service';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import vxContactusUpload from '../vx-contactus-upload/vx-contactus-upload.vue';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import flyoutBannerMixin from '../../common/vx-flyout-banner/vx-flyout-banner-mixin';
import ManageProfileShoppingListService from '../../common/services/manage-profile-shopping-lists-service';
import detectDeviceMixin from '../../common/mixins/detect-device-mixin';
import CommonService from '../../common/services/common-service';
import mobileMixin from '../../common/mixins/mobile-mixin';
import vxDispenserConcern from './vx-dispenser-concern/vx-dispenser-concern.vue';
import vxDispenserReplacement from './vx-dispenser-replacement/vx-dispenser-replacement.vue';
import vxProductConcern from './vx-product-concern/vx-product-concern.vue';
import vxGeneralInquiry from './vx-general-inquiry/vx-general-inquiry.vue';
import vxContactFileUpload from './vx-contact-file-upload/vx-contact-file-upload.vue';

import {
  globalEventBus,
} from '../../../modules/event-bus';
import {
  flyoutStatus,
  UserRoles,
} from '../../common/mixins/vx-enums';
/* eslint-disable */
export default {
  name: 'vx-service-ticket',
  components: {
    vxDropdownPrimary,
    vxModal,
    vxContactusUpload,
    TheMask,
    vxSpinner,
    vxDispenserConcern,
    vxDispenserReplacement,
    vxProductConcern,
    vxGeneralInquiry,
    vxContactFileUpload,
  },
  mixins: [flyoutBannerMixin, detectDeviceMixin, mobileMixin],
  props: {
    // Text coming from property file
    i18n: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      form: {
        topicOfInquiry: {},
      },
      attachments: [],
      acsService: new AcsService(),
      veeCustomErrorMessage: {
        en: {
          custom: {
            topicOfInquiry: {
              required: this.i18n.serviceTicket.formErrorMsg.topicOfInquiry.required,
            },
          },
        },
      },
      globals,
      masked: true,
      userDetails: {},
      manageProfileShoppingListService: new ManageProfileShoppingListService(),
      commonService: new CommonService(),
      regionList: [],
      topicOfInquiryList: [],
      topicOfInquiry: {},
      totalFileSize: 0,
      fileSizeExceed: false,
      desiredOrder: [
        "Dispenser Concern",
        "Product Concern",
        "Request Dispenser Keys",
        "General Inquiry"
      ],
      selectedFiles: '',
    };
  },
  created() {
    Validator.localize(this.veeCustomErrorMessage);
  },
  mounted() {
    // Call to get topic of inquiry data
    this.$refs.topicDropdown.resetDropdown();
    this.acsService.getTopicOfInquiry({}, this.handleTopicOfInquiryResponse, this.handleTopicOfInquiryError);
    // Populate order id if navigation from order details page
    const orderId = this.globals.getUrlParam('orderConfirmationNumber');
    if (this.globals.getIsLoggedIn() && orderId) {
      this.form.orderNumber = orderId;
    }
  },
  computed: {
    /**
         * This function disables file attachment functionality when 10 files are uploaded
         */
    disableUpload() {
      if (this.attachments.length === 10) {
        return true;
      }
      return false;
    },
  },
  methods: {
    /**
       * This function handles response of get inquiry data call and creates topic of inquiry dropdown values
       */
    handleTopicOfInquiryResponse(response) {
      if (response.data && response.data.topicOfInquiry && response.data.topicOfInquiry.entry) {
        response.data.topicOfInquiry.entry.forEach((item, index) => {
          this.$set(this.topicOfInquiryList, this.desiredOrder.indexOf(item.value), {
            label: item.value,
            value: item.key,
          });
        });
      }
    },
    /**
         * This function handles error of get inquiry data call
         */
    handleTopicOfInquiryError() {
    },
    /**
         * This function submits the form only if there are no errors in the form
         */
    handleSubmit(e) {
      e.preventDefault();
      // this.$validator.validateAll()
      //   .then((result) => {
      //     if (result) {
      //       this.form.topicOfInquiry.key = this.topicOfInquiry.value;
      //       this.form.topicOfInquiry.value = this.topicOfInquiry.label;
      //       const requestConfig = {};
      //       requestConfig.data = this.form;
      //       this.acsService.createServiceTicket(requestConfig, this.handleSubmitResponse, this.handleSubmitError);
      //       this.$refs.spinner.showSpinner();
      //     } else {
      //       this.globals.setFocusByName(this.$el, this.globals.getElementName(this.errors));
      //     }
      //   });
      this.$refs.formFields.validate().then((success) => {
        if (success) {
          const requestConfig = {};
          const formData = this.$refs.formFields.getFormData();
          formData.topicOfInquiry = {};
          formData.topicOfInquiry.key = this.topicOfInquiry.value;
          formData.topicOfInquiry.value = this.topicOfInquiry.label;
          
          console.log('Form submitted:', formData);

          if (this.topicOfInquiry.label === 'Request Dispenser Keys') {
            const {
              keyQuantities,
              dispenserType,
              streetAddress,
              city,
              companyName,
              country,
              email,
              firstName,
              lastName,
              phone,
              postalCode,
              region,
              topicOfInquiry,
              ...rest
            } = formData;
            const additionalFields = Object.entries(rest).map(([key, value]) => ({
              'key': key,
              'value': value
            }));
            requestConfig.data = {
              keyQuantities,
              dispenserType,
              streetAddress,
              city,
              companyName,
              country,
              email,
              firstName,
              lastName,
              phone,
              postalCode,
              region,
              additionalFields,
            };
            this.acsService.submitDispenserTicket(
              requestConfig,
              this.handleSubmitResponse,
              this.handleSubmitError,
            );
          } else {
            const { streetAddress,
              city,
              ticketComments,
              companyName,
              country,
              email,
              firstName,
              jobTitle,
              lastName,
              orderNumber,
              phone,
              postalCode,
              region,
              topicOfInquiry,
              ...rest
            } = formData;
            const additionalFields = Object.entries(rest).map(([key, value]) => ({
              'key': key,
              'value': value
            }));
            requestConfig.data = {
              streetAddress,
              city,
              ticketComments,
              companyName,
              country,
              email,
              firstName,
              jobTitle,
              lastName,
              orderNumber,
              phone,
              postalCode,
              region,
              topicOfInquiry,
              additionalFields,
            };
            this.acsService.createServiceTicket(requestConfig, this.handleSubmitResponse, this.handleSubmitError);
          }
          this.$refs.spinner.showSpinner();
        }
      });
    },
    /**
         * This function handles the response of submit call and submits the attachements if there are any
         */
    handleSubmitResponse(response) {
      if (response.data) {
        this.$refs.spinner.hideSpinner();
        if (this.selectedFiles.length) {
          const formData = new FormData();
          this.selectedFiles.forEach((file, index) => {
            formData.append('file', file);
          });
          const requestConfig = {};
          requestConfig.data = formData;
          this.acsService.submitAttachments(requestConfig, this.handleSubmitAttachmentsResponse, this.handleSubmitAttachmentsError, response.data.id);
          this.$refs.spinner.showSpinner();
        } else {
          this.showFlyoutNextPage(flyoutStatus.success, this.i18n.serviceTicket.submitSuccess, true, true, 10000);
          this.navigateToParent();
        }
      }
    },
    /**
         * This function handles the error of submit call
         */
    handleSubmitError() {
      this.showFlyout(flyoutStatus.error, this.i18n.serviceTicket.submitFailure, true);
      globalEventBus.$emit('announce', this.i18n.serviceTicket.submitFailure);
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function handles the response of attachment submit call
         */
    handleSubmitAttachmentsResponse() {
      this.$refs.spinner.hideSpinner();
      this.showFlyoutNextPage(flyoutStatus.success, this.i18n.serviceTicket.submitSuccess, true, true, 10000);
      this.navigateToParent();
    },
    /**
         * This function handles the error of attachment submit call
         */
    handleSubmitAttachmentsError() {
      this.showFlyout(flyoutStatus.error, this.i18n.serviceTicket.submitFailure, true);
      globalEventBus.$emit('announce', this.i18n.serviceTicket.submitFailure);
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function redirects to the page from where the user navigated to contact us page
         */
    navigateToParent() {
      const orderNumber = this.globals.getUrlParam('orderConfirmationNumber');
      if (this.globals.loggedIn && orderNumber) {
        const url = `${this.globals.getNavBaseUrl()}${this.globals.navigations.orderDetails}${orderNumber}`;
        window.location = url;
      } else if (this.globals.loggedIn) {
        this.globals.navigateToUrl('supportTickets');
      } else if (!this.globals.loggedIn) {
        this.globals.navigateToUrl('home');
      }
      globalEventBus.$emit('announce', this.i18n.serviceTicket.submitSuccess);
    },
    /**
         * This function validates zip code field
         */
    zipCodeValidation() {
      if (this.form.country === 'US') {
        return {
          required: true,
          max: 20,
          numeric: true,
        };
      }
      return {
        required: true,
        max: 20,
        alpha_num: true,
      };
    },

    /**
         * This function finds job title
         * @param  {String} role role of the B2b User
         */
    findJobTitle(role) {
      let userRole = '';
      if (role === UserRoles.ADMINISTRATORS) {
        userRole = this.i18n.serviceTicket.userRoles.administrators;
      } else if (role === UserRoles.BUYERS) {
        userRole = this.i18n.serviceTicket.userRoles.buyers;
      } else if (role === UserRoles.ADMIN) {
        userRole = this.i18n.serviceTicket.userRoles.admin;
      } else if (role === UserRoles.CUSTOMER) {
        userRole = this.i18n.serviceTicket.userRoles.customer;
      }
      return userRole;
    },    
    handleRemoveAttachment(key) {
      this.removeAttachment(key)
    },
    updateSelectedFiles(files) {
      this.selectedFiles = files;
    },
    updateErrorMessage(message) {
      this.fileSizeExceed = message;
    },
  },
};
