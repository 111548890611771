var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group" }, [
      _c(
        "label",
        { staticClass: "control-label", attrs: { for: "formFileLg" } },
        [
          _vm._v(
            "Choose a file or image to attach. Acceptable file types include jpg,\n      jpeg, png, gif, bmp, pdf (max 5mb)."
          )
        ]
      ),
      _vm._v(" "),
      _c("input", {
        ref: "fileInput",
        staticStyle: { display: "none" },
        attrs: {
          type: "file",
          id: "formFileLg",
          accept: ".jpg, .jpeg, .png, .gif, .bmp, .pdf",
          multiple: ""
        },
        on: { change: _vm.handleFileSelect }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _c("span", { staticClass: "input-group-btn" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { type: "button" },
              on: { click: _vm.triggerFileSelect }
            },
            [_vm._v("Browse")]
          )
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.fileNames,
              expression: "fileNames"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", readonly: "" },
          domProps: { value: _vm.fileNames },
          on: {
            click: _vm.triggerFileSelect,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.fileNames = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _vm.errorMessage
      ? _c("p", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.errorMessage))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "list-group mt-3" },
      _vm._l(_vm.selectedFiles, function(file, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass:
              "list-group-item d-flex justify-content-between align-items-center"
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(file.name) +
                " (" +
                _vm._s(_vm.formatFileSize(file.size)) +
                ")\n      "
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-danger btn-sm",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.removeFile(index)
                  }
                }
              },
              [_vm._v("x")]
            )
          ]
        )
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-137b74cf", { render: render, staticRenderFns: staticRenderFns })
  }
}