var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dispenser-concern" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validate($event)
            }
          }
        },
        [
          _c("fieldset", [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "form-group col-sm-12 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "companyName",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.companyName) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyName,
                        expression: "form.companyName"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          required: true,
                          regex: /^[A-Za-z\s\’'-]+$/,
                          max: 80
                        },
                        expression:
                          "{ required: true, regex: /^[A-Za-z\\s\\’'-]+$/ ,max: 80 }"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "input-error": _vm.errors.has("companyName") },
                    attrs: {
                      type: "text",
                      maxlength: "80",
                      id: "companyName",
                      name: "companyName",
                      "aria-label": _vm.i18n.companyName,
                      "aria-describedby": _vm.errors.has("companyName")
                        ? "companyName_error_msg"
                        : null
                    },
                    domProps: { value: _vm.form.companyName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "companyName", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("companyName")
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-xs-2",
                          attrs: {
                            "aria-hidden": !_vm.errors.has("companyName")
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "companyName_error_msg"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("companyName")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "firstName",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.firstName) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.firstName,
                        expression: "form.firstName"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          required: true,
                          regex: /^[A-Za-z\s\’'-]+$/,
                          max: 40
                        },
                        expression:
                          "{ required: true, regex: /^[A-Za-z\\s\\’'-]+$/ ,max: 40 }"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "input-error": _vm.errors.has("firstName") },
                    attrs: {
                      type: "text",
                      maxlength: "40",
                      id: "firstName",
                      name: "firstName",
                      "aria-label": _vm.i18n.firstName,
                      "aria-describedby": _vm.errors.has("firstName")
                        ? "firstname_error_msg"
                        : null
                    },
                    domProps: { value: _vm.form.firstName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "firstName", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("firstName")
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-xs-2",
                          attrs: { "aria-hidden": !_vm.errors.has("firstName") }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "firstname_error_msg"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("firstName")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "lastName",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.lastName) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.lastName,
                        expression: "form.lastName"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          required: true,
                          regex: /^[A-Za-z\s\’'-]+$/,
                          max: 80
                        },
                        expression:
                          "{ required: true, regex: /^[A-Za-z\\s\\’'-]+$/ ,max: 80 }"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "input-error": _vm.errors.has("lastName") },
                    attrs: {
                      type: "text",
                      maxlength: "80",
                      id: "lastName",
                      name: "lastName",
                      "aria-label": _vm.i18n.lastName,
                      "aria-describedby": _vm.errors.has("lastName")
                        ? "lastname_error_msg"
                        : null
                    },
                    domProps: { value: _vm.form.lastName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "lastName", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("lastName")
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-xs-2",
                          attrs: { "aria-hidden": !_vm.errors.has("lastName") }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "lastname_error_msg"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("lastName")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "email",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.email) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|email|max:80",
                        expression: "'required|email|max:80'"
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.email,
                        expression: "form.email"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "input-error": _vm.errors.has("email") },
                    attrs: {
                      type: "text",
                      maxlength: "80",
                      name: "email",
                      "aria-label": _vm.i18n.email,
                      id: "email"
                    },
                    domProps: { value: _vm.form.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "email", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("email")
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-xs-2",
                          attrs: { "aria-label": !_vm.errors.has("email") }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: { "aria-live": "polite", role: "alert" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("email")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "telephone",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.phone) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("the-mask", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:15",
                        expression: "'required|min:15'"
                      }
                    ],
                    staticClass: "phone-number form-control",
                    class: { "input-error": _vm.errors.has("telephone") },
                    attrs: {
                      masked: _vm.masked,
                      mask: "+1 ###-###-####",
                      type: "tel",
                      name: "telephone",
                      id: "telephone"
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("telephone")
                    ? _c("div", { staticClass: "mt-xs-2" }, [
                        _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            attrs: { "aria-live": "polite", role: "alert" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.first("telephone")) +
                                "\n            "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-sm-12 pr-sm-0 px-xs-0 pl-sm-3" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "address",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.streetAddress) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.streetAddress,
                        expression: "form.streetAddress"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:60",
                        expression: "'required|max:60'"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "input-error": _vm.errors.has("address") },
                    attrs: {
                      type: "text",
                      id: "address",
                      maxlength: "60",
                      name: "address",
                      "aria-label": _vm.i18n.streetAddress,
                      "aria-describedby": _vm.errors.has("address")
                        ? "address_error_msg"
                        : null
                    },
                    domProps: { value: _vm.form.streetAddress },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "streetAddress", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("address")
                    ? _c(
                        "div",
                        {
                          attrs: { "aria-hidden": !_vm.errors.has("address") }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "address_error_msg"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("address")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "form-group col-sm-12 pr-sm-0 px-xs-0 pl-sm-3" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "inputCity",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.city) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.city,
                        expression: "form.city"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:80",
                        expression: "'required|max:80'"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "input-error": _vm.errors.has("city") },
                    attrs: {
                      type: "text",
                      id: "inputCity",
                      name: "city",
                      "aria-label": _vm.i18n.city,
                      maxlength: "80",
                      "aria-describedby": _vm.errors.has("city")
                        ? "address_city_error_msg"
                        : null
                    },
                    domProps: { value: _vm.form.city },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "city", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("city")
                    ? _c(
                        "div",
                        {
                          staticClass: " mt-xs-2",
                          attrs: { "aria-label": !_vm.errors.has("city") }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "address_city_error_msg"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("city")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "form-group col-sm-12 pr-sm-0 px-xs-0 pl-sm-3" },
                [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "contact-label",
                        attrs: {
                          "aria-hidden":
                            !_vm.isTablet() && _vm.isDeviceAndroid()
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.i18n.country) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    ref: "countryDropdown",
                    attrs: {
                      "required-aria": "true",
                      dropdownValues: _vm.i18n.countryList,
                      "aria-label": _vm.i18n.country,
                      name: "country"
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.setCountry($event)
                      }
                    },
                    model: {
                      value: _vm.form.country,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "country", $$v)
                      },
                      expression: "form.country"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "contact-label",
                        attrs: {
                          "aria-hidden":
                            !_vm.isTablet() && _vm.isDeviceAndroid()
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.i18n.state) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    ref: "regionDropdown",
                    attrs: {
                      "describedby-aria": "address_state_error_msg",
                      "required-aria": "true",
                      dropdownValues: _vm.regionList,
                      name: "state",
                      "aria-label": _vm.i18n.state,
                      dropdownError: _vm.errors.has("state")
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.form.region = $event.label
                      }
                    },
                    model: {
                      value: _vm.form.region,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "region", $$v)
                      },
                      expression: "form.region"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("state"),
                          expression: "errors.has('state')"
                        }
                      ],
                      staticClass: "mt-xs-2"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "polite",
                            role: "alert",
                            id: "address_state_error_msg"
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.first("state")) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "inputZip",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.postalCode) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.postalCode,
                        expression: "form.postalCode"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.zipCodeValidation(),
                        expression: "zipCodeValidation()"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "input-error": _vm.errors.has("zipcode") },
                    attrs: {
                      type: "text",
                      id: "inputZip",
                      maxlength: "20",
                      name: "zipcode",
                      "aria-label": _vm.i18n.postalCode,
                      "aria-describedby": _vm.errors.has("zipcode")
                        ? "address_zip_error_msg"
                        : null
                    },
                    domProps: { value: _vm.form.postalCode },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "postalCode", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("zipcode")
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-xs-2",
                          attrs: { "aria-label": !_vm.errors.has("zipcode") }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "address_zip_error_msg"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("zipcode")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "sku",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.sku) +
                          " (From label inside dispenser)\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.sku,
                        expression: "form.sku"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "input-error": _vm.errors.has("sku") },
                    attrs: {
                      type: "text",
                      maxlength: "40",
                      id: "sku",
                      name: "sku",
                      "aria-label": _vm.i18n.sku,
                      "aria-describedby": _vm.errors.has("sku")
                        ? "sku_error_msg"
                        : null
                    },
                    domProps: { value: _vm.form.sku },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "sku", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("sku")
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-xs-2",
                          attrs: { "aria-hidden": !_vm.errors.has("sku") }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "sku_error_msg"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("sku")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "manufacture_date",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.manufacture_date) +
                          " (From label inside dispenser)\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.manufacture_date,
                        expression: "form.manufacture_date"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true },
                        expression: "{ required: true}"
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      "input-error": _vm.errors.has("manufacture_date")
                    },
                    attrs: {
                      type: "text",
                      maxlength: "40",
                      id: "manufacture_date",
                      name: "manufacture_date",
                      "aria-label": _vm.i18n.manufacture_date,
                      "aria-describedby": _vm.errors.has("manufacture_date")
                        ? "manufacture_date_error_msg"
                        : null
                    },
                    domProps: { value: _vm.form.manufacture_date },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "manufacture_date",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("manufacture_date")
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-xs-2",
                          attrs: {
                            "aria-hidden": !_vm.errors.has("manufacture_date")
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "manufacture_date_error_msg"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("manufacture_date")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "refill_sku",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.refill_sku) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.refill_sku,
                        expression: "form.refill_sku"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true },
                        expression: "{ required: true }"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "input-error": _vm.errors.has("refill_sku") },
                    attrs: {
                      type: "text",
                      maxlength: "80",
                      id: "refill_sku",
                      name: "refill_sku",
                      "aria-label": _vm.i18n.refill_sku,
                      "aria-describedby": _vm.errors.has("refill_sku")
                        ? "refill_sku_error_msg"
                        : null
                    },
                    domProps: { value: _vm.form.refill_sku },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "refill_sku", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("refill_sku")
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-xs-2",
                          attrs: {
                            "aria-hidden": !_vm.errors.has("refill_sku")
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "refill_sku_error_msg"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("refill_sku")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "where_purchase_refill",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.where_purchase_refill) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.where_purchase_refill,
                        expression: "form.where_purchase_refill"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true },
                        expression: "{ required: true }"
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      "input-error": _vm.errors.has("where_purchase_refill")
                    },
                    attrs: {
                      type: "text",
                      maxlength: "40",
                      id: "where_purchase_refill",
                      name: "where_purchase_refill",
                      "aria-label": _vm.i18n.where_purchase_refill,
                      "aria-describedby": _vm.errors.has(
                        "where_purchase_refill"
                      )
                        ? "where_purchase_refill_error_msg"
                        : null
                    },
                    domProps: { value: _vm.form.where_purchase_refill },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "where_purchase_refill",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("where_purchase_refill")
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-xs-2",
                          attrs: {
                            "aria-hidden": !_vm.errors.has(
                              "where_purchase_refill"
                            )
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "where_purchase_refill_error_msg"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.errors.first("where_purchase_refill")
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "form-group col-sm-12 pr-sm-0 px-xs-0 pl-sm-3" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "comments",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.i18n.questionsOrComments) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.ticketComments,
                        expression: "form.ticketComments"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control rounded-0 comments",
                    class: { "input-error": _vm.errors.has("comments") },
                    attrs: {
                      maxlength: "1024",
                      type: "text",
                      id: "comments",
                      name: "comments",
                      rows: "3",
                      "aria-label": _vm.i18n.questionsOrComments
                    },
                    domProps: { value: _vm.form.ticketComments },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "ticketComments",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "info-text mb-xs-0 mt-xs-2" }, [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          1024 - _vm.form.ticketComments.length
                        )
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { attrs: { "aria-live": "polite" } }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.i18n.commentsCharacterCount) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.errors.has("comments")
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-xs-2",
                          attrs: { "aria-label": !_vm.errors.has("comments") }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: { "aria-live": "polite", role: "alert" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("comments")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "legend_ai" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Contact Information")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "legend_ai" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Contact Address")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "legend_ai" }, [
      _c("span", { staticClass: "sr-only" }, [
        _vm._v("Order Number and comment")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-10e6fc22", { render: render, staticRenderFns: staticRenderFns })
  }
}