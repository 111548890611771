import globals from '../../common/globals';
import vxServiceTicket from '../vx-service-ticket/vx-service-ticket.vue';

if (document.querySelectorAll('.contact-page-heading').length) {
  document.querySelector('.contact-page-heading').style.display = 'none';
}

export default {
  name: 'vx-contact-us',
  components: {
    vxLiveChatRender: () => import(/* webpackChunkName: "vx-live-chat" */ '../vx-live-chat/vx-live-chat.vue'),
    vxServiceTicket,
  },
  props: {
    // Text coming from property file
    i18n: {
      type: Object,
      default: {},
    },
    // Dynamic css class for live chat
    liveChatTheme: {
      type: String,
      required: false,
    },
    // Shows call us section
    showContactUs: {
      type: Boolean,
      required: true,
      default: true,
    },
    // Data about chat section
    imageTextData: {
      type: Object,
      required: false,
    },
    // Indicates if live chat section is enabled on contact us page
    isLiveChatEnabled: {
      type: Boolean,
      default: false,
      required: true,
    },
    // Topic of inquiry dropdown data
    topicOfInquiry: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      globals,
    };
  },
  computed: {

  },
  methods: {

  },
};
